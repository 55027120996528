import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { BoldText } from '../../components/bold-text/bold-text';
import { InlineCode } from '../../components/inline-code/inline-code';
import { ItalicText } from '../../components/italic-text/italic-text';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Entwickler:innen sind oft bequem. Wir lieben Tools und Features, die uns das Leben leichter machen. Das gilt
insbesondere für die Gestaltung von Webinhalten mit CSS.`}</p>
    <p>{`Eine große Herausforderung ist die Definition von CSS-Regeln, die nur bestimmte Bereiche stylen, ohne
`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/Specificity"
      }}>{`allzu spezifische Selektoren`}</a>{` zu schreiben, die nur
schwer zu überschreiben sind. Außerdem sollte man Selektoren nicht zu eng an die DOM-Struktur koppeln, da diese
anfällig für Änderungen ist.`}</p>
    <p>{`Verschiedene JavaScript-Frameworks haben unterschiedliche Lösungen für dieses Problem entwickelt: React verwendet
`}<a parentName="p" {...{
        "href": "https://create-react-app.dev/docs/adding-a-css-modules-stylesheet/"
      }}>{`CSS-Module`}</a>{`, die das Scoping von CSS ermöglichen,
indem sie automatisch einen eindeutigen Klassennamen für Komponenten-Styles erstellen. In Angular werden die Styles
`}<a parentName="p" {...{
        "href": "https://angular.io/guide/view-encapsulation"
      }}>{`mit benutzerdefinierten HTML-Attributen gekapselt`}</a>{`, so dass sie sich
nicht auf den Rest der Applikation auswirken.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMBBf/EABYBAQEBAAAAAAAAAAAAAAAAAAMBAv/aAAwDAQACEAMQAAABpVp3lrsN/8QAGRAAAgMBAAAAAAAAAAAAAAAAAQIAAxEh/9oACAEBAAEFAkrAjrqNVhFcCx+N/8QAFxEBAAMAAAAAAAAAAAAAAAAAAQMQEv/aAAgBAwEBPwFkdFf/xAAYEQACAwAAAAAAAAAAAAAAAAAAAgERQf/aAAgBAgEBPwGV0tj/xAAaEAADAQADAAAAAAAAAAAAAAAAAREhAhJh/9oACAEBAAY/Am1DN2xmnM7e0iP/xAAcEAEAAgIDAQAAAAAAAAAAAAABACERUTFhkaH/2gAIAQEAAT8haYsdxAVtHyVK9uGqzcLCG+CaiE//2gAMAwEAAgADAAAAEC8v/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERUf/aAAgBAwEBPxCW0qP/xAAXEQEBAQEAAAAAAAAAAAAAAAABACFh/9oACAECAQE/EAeyG2//xAAdEAEBAAIBBQAAAAAAAAAAAAABEQAhQTFRYXHB/9oACAEBAAE/EFyjHejG89LjskdxUkYqQPF1PZiRewMIM2YR4chqyYdgngO/P3P/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Eine Schachtel mit sechs Donuts in verschiedenen Farben.",
          "title": "Eine Schachtel mit sechs Donuts in verschiedenen Farben.",
          "src": "/static/6dcdbd8a4856f0d8ffa5e42c0aadefd2/e5166/pexels-cottonbro-donuts.jpg",
          "srcSet": ["/static/6dcdbd8a4856f0d8ffa5e42c0aadefd2/f93b5/pexels-cottonbro-donuts.jpg 300w", "/static/6dcdbd8a4856f0d8ffa5e42c0aadefd2/b4294/pexels-cottonbro-donuts.jpg 600w", "/static/6dcdbd8a4856f0d8ffa5e42c0aadefd2/e5166/pexels-cottonbro-donuts.jpg 1200w", "/static/6dcdbd8a4856f0d8ffa5e42c0aadefd2/b17f8/pexels-cottonbro-donuts.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Foto: © cottonbro studio / pexels.com`}</em></p>
    <p>{`Aber warum ist das Scoping von Styles nicht allein mit CSS möglich? Mittlerweile geht das! Mithilfe der neuen
CSS-Regel `}<InlineCode mdxType="InlineCode">{`@scope`}</InlineCode>{` können wir Styles auf bestimmte DOM-Abschnitte beschränken. Ihr könnt sogar
untere Grenzen für den Geltungsbereich festlegen und einen sogenannten `}<ItalicText mdxType="ItalicText">{`Donut Scope`}</ItalicText>{` schaffen.`}</p>
    <p>{`Ich erkläre euch die Grundlagen dieses neuen Features und erprobe ihre Anwendung in einer Angular-Demo.`}</p>
    <h2>{`Demo: CSS Scope vs View Encapsulation`}</h2>
    <p>{`Meine `}<a parentName="p" {...{
        "href": "https://github.com/alexlehner86/test-css-scope"
      }}>{`Demo-Anwendung`}</a>{` wurde mit Angular 17 erstellt. Sie enthält einen
Header und einen Hauptabschnitt mit einigen Absätzen, Links und mehreren Rezeptblöcken.`}</p>
    <p>{`Die Komponente `}<InlineCode mdxType="InlineCode">{`app-recipe-card`}</InlineCode>{` (gelber Hintergrund) verwendet die neue Scope-Funktion, um nur
die HTML-Elemente in ihrem eigenen Teilbaum anzusprechen. Wichtig: Zum Zeitpunkt des Verfassens dieses Artikels
`}<a parentName="p" {...{
        "href": "https://caniuse.com/css-cascade-scope"
      }}>{`funktioniert Scope nur in Chrome, Edge und Safari`}</a>{`!`}</p>
    <p>{`Zum Vergleich habe ich auch die Komponente `}<InlineCode mdxType="InlineCode">{`app-recipe-card-old`}</InlineCode>{` (blauer Hintergrund) definiert,
welche die Standard-View-Encapsulation von Angular verwendet. Untersucht die Elemente mit den Entwicklerwerkzeugen
eures Browsers.`}</p>
    <iframe src="https://alexlehner86.github.io/test-css-scope/" title="CSS Scope vs View Encapsulation Demo" loading="lazy"></iframe>
    <p>{`Wie die Demo zeigt, können wir mit CSS-Scope einfache Selektoren mit geringer Spezifität und ohne zusätzliche
Klassennamen schreiben. Schauen wir uns nun genauer an, wie das Ganze funktioniert.`}</p>
    <h2>{`Die Grundlagen von CSS-Scoping`}</h2>
    <p>{`Das neue CSS-Scope-Feature ist im Modul `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/css-cascade-6/"
      }}>{`CSS Cascading and Inheritance Level 6`}</a>{`
definiert, das zum Zeitpunkt des Verfassens dieses Beitrags noch ein Arbeitsentwurf ist. Es besagt:`}</p>
    <blockquote lang="en">
    A <BoldText mdxType="BoldText">scope</BoldText> is a subtree or fragment of a document, which can be used by selectors for more targeted matching. A scope is formed by determining: The <BoldText mdxType="BoldText">scoping root node</BoldText>, which acts as the upper bound of the scope, and optionally the <BoldText mdxType="BoldText">scoping limit elements</BoldText>, which act as the lower bounds.
    </blockquote>
    <p>{`Das heißt, ihr müsst das Root-Element des DOM-Teilbaums definieren, auf den ihr eure Styles anwenden möchtet.
Optional könnt ihr auch innere Elemente auflisten, welche die untere Grenze des Scopes darstellen.`}</p>
    <h3>{`Die @scope CSS-Regel`}</h3>
    <p>{`Die `}<InlineCode mdxType="InlineCode">{`app-recipe-card`}</InlineCode>{` Komponente in meiner Demo enthält Überschriften, Absätze und eine
ungeordnete Liste. Wir stylen sie mit dem folgenden CSS-Code:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`@scope (app-recipe-card) {
    h3 {
        color: var(--highlight-color);
        font-size: 1.3rem;
    }

    p {
        margin-block: 0 1em;
    }

    ul {
        list-style: square;
        margin: 0;
        padding-inline-start: 1.25rem;
    }

    li::marker{
        color: var(--highlight-color);
    }
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Der obige `}<InlineCode mdxType="InlineCode">{`@scope`}</InlineCode>{`-Block definiert `}<InlineCode mdxType="InlineCode">{`app-recipe-card`}</InlineCode>{` als
die `}<ItalicText mdxType="ItalicText">{`Scoping Root`}</ItalicText>{`, welche die obere Grenze des Teilbaums bestimmt, auf den wir abzielen. Nun
beziehen sich alle enthaltenen Style-Regeln, wie `}<InlineCode mdxType="InlineCode">{`h3 { ... }`}</InlineCode>{`, nur auf diesen begrenzten Abschnitt
des DOM.`}</p>
    <h3>{`Einen Donut Scope erzeugen`}</h3>
    <p>{`Manchmal reicht es nicht aus, nur einen Scoping-Root zu setzen. In `}<a parentName="p" {...{
        "href": "https://angular.io"
      }}>{`Angular`}</a>{` und `}<a parentName="p" {...{
        "href": "https://react.dev/"
      }}>{`React`}</a>{`
verschachteln wir meistens Komponenten innerhalb anderer Komponenten, um komplexe Benutzeroberflächen zu erstellen. Wie
können wir sicherstellen, dass sich das Styling der Eltern-Komponente nicht auf ihre Kind-Komponenten auswirkt?`}</p>
    <p>{`Die `}<InlineCode mdxType="InlineCode">{`@scope`}</InlineCode>{`-Regel akzeptiert auch ein `}<ItalicText mdxType="ItalicText">{`Scoping Limit`}</ItalicText>{`, das die untere
Begrenzung bestimmt. In meiner Demo sind die Rezeptblöcke in der Komponente `}<InlineCode mdxType="InlineCode">{`app-recipes-list`}</InlineCode>{`
eingebunden. Hier ist ein Teil des CSS-Codes:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`@scope (app-recipes-list) to (app-recipe-card, app-recipe-card-old) {
    p {
        font-style: italic;
    }
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Auf diese Weise werden nur die von der übergeordneten Komponente `}<InlineCode mdxType="InlineCode">{`app-recipes-list`}</InlineCode>{` definierten
Absätze kursiv gesetzt. Die Absätze innerhalb der untergeordneten Komponenten `}<InlineCode mdxType="InlineCode">{`app-recipe-card`}</InlineCode>{`
und `}<InlineCode mdxType="InlineCode">{`app-recipe-card-old`}</InlineCode>{` sind davon nicht betroffen.`}</p>
    <p>{`Diese Art von Scoping – mit einer oberen und unteren Grenze – wird als `}<ItalicText mdxType="ItalicText">{`Donut Scope`}</ItalicText>{` bezeichnet.
Ich kann euch den Artikel
`}<a parentName="p" {...{
        "href": "https://developer.chrome.com/docs/css-ui/at-scope"
      }}>{`“Limit the reach of your selectors with the CSS @scope at-rule”`}</a>{`
von Bramus Van Damme empfehlen. Er enthält tolle Visualisierungen verschiedener Scope-Szenarien.`}</p>
    <h3>{`Der :scope Selektor`}</h3>
    <p>{`Eine weitere nützliche Funktion ist der Selektor `}<InlineCode mdxType="InlineCode">{`:scope`}</InlineCode>{`. Damit könnt ihr innerhalb
des `}<InlineCode mdxType="InlineCode">{`@scope`}</InlineCode>{`-Blocks auf das Root-Element des Scopings selbst abzielen. Hier ist ein Beispiel aus
meiner Demo:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`@scope (app-recipe-card) {
    :scope {
        --highlight-color: rgb(194 34 2);
        display: block;
        background: lightgoldenrodyellow;
        color: black;
        padding: 1rem;
    }
}`}</code>{`
        `}</deckgo-highlight-code>
    <h3>{`Browser-Unterstützung`}</h3>
    <p>{`Wie bitte? Ihr findet das Scope-Feature total genial und wollt es sofort in allen Projekten einsetzen? Leider müsst
ihr euch noch etwas gedulden.`}</p>
    <p>{`Im Moment wird die `}<InlineCode mdxType="InlineCode">{`@scope`}</InlineCode>{`-Regel nur
`}<a parentName="p" {...{
        "href": "https://caniuse.com/css-cascade-scope"
      }}>{`von Chrome 118+, Edge 118+ und Safari 17.4+ unterstützt`}</a>{`. Firefox unterstützt
das Feature noch nicht, aber `}<a parentName="p" {...{
        "href": "https://bugzilla.mozilla.org/show_bug.cgi?id=1830512"
      }}>{`Mozilla arbeitet aktiv an der Implementierung`}</a>{`.
Hoffen wir auf eine browserübergreifende Unterstützung im Laufe des Jahres!`}</p>
    <h2>{`Wie ihr @scope in einer Angular-Applikation verwendet`}</h2>
    <p>{`In Angular sind `}<a parentName="p" {...{
        "href": "https://angular.io/guide/view-encapsulation"
      }}>{`Komponenten-Styles standardmäßig gekapselt`}</a>{`.
Das Framework erstellt eigene HTML-Attribute wie `}<InlineCode mdxType="InlineCode">{`_ngcontent-pmm-6`}</InlineCode>{`, fügt sie in die generierten
HTML-Elemente ein und ändert die CSS-Selektoren der Komponente so, dass sie nur auf die View der Komponente angewendet werden.`}</p>
    <p>{`Wenn ihr stattdessen die `}<InlineCode mdxType="InlineCode">{`@scope`}</InlineCode>{`-Regel verwenden möchtet, müsst ihr View Encapsulation für jede
Komponente manuell deaktivieren.`}</p>
    <h3>{`Die View Encapsulation abschalten`}</h3>
    <p>{`Um die View Encapsulation zu deaktivieren, müsst ihr im `}<a parentName="p" {...{
        "href": "https://angular.io/api/core/Component"
      }}>{`Dekorator der Komponente`}</a>{`
für `}<InlineCode mdxType="InlineCode">{`encapsulation`}</InlineCode>{` den Wert `}<InlineCode mdxType="InlineCode">{`ViewEncapsulation.None`}</InlineCode>{` setzen.
Hier ein Beispiel aus meiner Demo:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`@Component({
    selector: 'app-recipe-card',
    standalone: true,
    templateUrl: './recipe-card.component.html',
    styleUrl: './recipe-card.component.css',
    encapsulation: ViewEncapsulation.None,
})
export class RecipeCardComponent {
    @Input({ required: true }) recipe!: Recipe;
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Jetzt werden eure CSS-Selektoren nicht mehr mit eigenen Attributen erweitert und die Styles der Komponente werden global
angewendet. Ihr solltet nun die `}<InlineCode mdxType="InlineCode">{`@scope`}</InlineCode>{`-Regel verwenden, um die Styles zu kapseln.`}</p>
    <h3>{`Vergleich des HTML- und CSS-Outputs`}</h3>
    <p>{`Wie wir gesehen haben, erfordert der Einsatz von CSS-Scope ein wenig Aufwand bei der Erstellung einer Komponente. Es ist
komfortabler, einfach die Standard-View-Encapsulation von Angular zu verwenden.`}</p>
    <p>{`Aber ich wage zu behaupten, dass die Vorteile von CSS-Scope diesen kleinen Aufwand rechtfertigen. Werfen wir einen Blick
auf den HTML- und CSS-Code, der für die Komponente `}<InlineCode mdxType="InlineCode">{`app-recipe-card`}</InlineCode>{` in meiner Demo erzeugt wird:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`/* HTML elements in the DOM */
<app-recipe-card>
    <h3>Pizza Margherita</h3>
    <p>The best pizza in town!</p>
    <h4>Ingredients</h4>
    <ul>
        <li>Cutting edge CSS features!</li>
        /* More list items */
    </ul>
</app-recipe-card>

/* Generated CSS code (excerpt) */
@scope (app-recipe-card) {
    h3 {
        color: var(--highlight-color);
        font-size: 1.3rem;
    }
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Vergleichen wir das mit dem HTML- und CSS-Code, der für die Komponente `}<InlineCode mdxType="InlineCode">{`app-recipe-card-old`}</InlineCode>{`
erzeugt wird:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<app-recipe-card-old _nghost-ng-c2291633987>
    <h3 _ngcontent-ng-c2291633987>Pizza Margherita (Old)</h3>
    <p _ngcontent-ng-c2291633987>The (second) best pizza in town!</p>
    <h4 _ngcontent-ng-c2291633987>Ingredients</h4>
    <ul _ngcontent-ng-c2291633987>
        <li _ngcontent-ng-c2291633987>DOM cluttering view encapsulation</li>
        /* More list items */
    </ul>
</app-recipe-card-old>
    
/* Generated CSS code (excerpt) */
h3[_ngcontent-ng-c2291633987] {
    color: var(--highlight-color);
    font-size: 1.3rem;
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Stellt euch nun vor, ihr müsst eure Applikation debuggen. Die Rezeptblöcke werden nicht richtig gerendert.
Ihr öffnet die Entwicklertools eures Browsers und untersucht das DOM und die angewandten Styles. Welcher Code wäre
leichter zu lesen und zu verstehen?`}</p>
    <p>{`Ich glaube, dass mich die unzähligen `}<InlineCode mdxType="InlineCode">{`_ngcontent-ng-c2291633987`}</InlineCode>{`-Attribute sehr ablenken würden.
Es würde mir leichter fallen, den `}<InlineCode mdxType="InlineCode">{`@scope (app-recipe-card) { h3 }`}</InlineCode>{` Selektor zu verstehen als
den `}<InlineCode mdxType="InlineCode">{`h3`}{`[_ngcontent-ng-c2291633987]`}</InlineCode>{` Selektor. 😉`}</p>
    <h2>{`Fazit`}</h2>
    <p>{`Zurück zu meiner ursprüngliche Frage: Wird das CSS Scope Feature die View Encapsulation von Angular ersetzen? Vielleicht.
Ich weiß es wirklich nicht. Im Idealfall wird das Angular-Team seinen Standard-Mechanismus zur View Encapsulation anpassen
und die `}<InlineCode mdxType="InlineCode">{`@scope`}</InlineCode>{`-Regel verwenden.`}</p>
    <p>{`Unabhängig davon, was das Angular-Team tut: Es ist jetzt schon sehr einfach, die View Encapsulation für eine Komponente
abzuschalten und die Styles innerhalb eines `}<InlineCode mdxType="InlineCode">{`@scope`}</InlineCode>{`-Blocks zu definieren. Der generierte HTML- und
CSS-Code ist besser lesbar und viel einfacher zu debuggen. Außerdem verringert es die Dateigröße der Webanwendung.`}</p>
    <p>{`Für mich steht fest: Sobald CSS-Scope browserübergreifend unterstützt wird, werde ich es in meinen Projekten einsetzen. 🤩`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      